
































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextareaShort from '@/common/components/STextareaShort.vue';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'UCIrvineChem1LDELN4OIQuantitativeObservations2',
  components: {STextarea, TableInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        additionalObservations: '',
        concentrationTableRows: [
          {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
          },
          {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
          },
        ],
      },
      concentrationColumns: [
        {
          label: 'Column 1',
          component: STextareaShort,
          prop: 'a',
          width: '20%',
        },
        {
          label: 'Column 2',
          component: STextareaShort,
          prop: 'b',
          width: '20%',
        },
        {
          label: 'Column 3',
          component: STextareaShort,
          prop: 'c',
          width: '20%',
        },
        {
          label: 'Column 4',
          component: STextareaShort,
          prop: 'd',
          width: '20%',
        },
        {
          label: 'Column 5',
          component: STextareaShort,
          prop: 'e',
          width: '20%',
        },
      ],
      questions: [
        {
          text: 'In the space below, please insert all remaining quantitative observations and calculations for the experiment.',
          inputName: 'additionalObservations',
        },
      ],
    };
  },
  methods: {},
});
